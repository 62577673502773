<!-- 积分规则使用详情 -->

<template>
  <div class="usage-details-dialog">
    <w-dialog ref="dialogRef" title="使用详情" width="40%" btnWidth="140px" top="20vh" confirmText="提交保存"
      @wConfirm="onConfirmBtn">
      <div class="flex">
        <div v-for="(item, index) in typeLabels" :key="index" class="details-type-title"
          :class="typeActive == index ? 'details-type-active' : ''" @click="onClickLabel(index)">{{ item.label }}
        </div>
      </div>
      <div class="flex m-m-t flex-wrap info">
        <div v-if="infoDatas.length == 0" class="no-data">暂无数据！</div>
        <div v-else v-for="(item, index) in infoDatas" :key="index" class="info-content s-m-t"
          :class="item.is_checked ? 'info-active' : ''" @click="onClickInfoLabel(item)">
          <div class="flex align-center">
            <div class="info-icon">
              <img src="@/assets/img/hotels.svg" alt="酒店" v-if="inu_type == 1">
              <img src="@/assets/img/catering.svg" alt="餐饮" v-if="inu_type == 2">
              <img src="@/assets/img/store.svg" alt="门店" v-if="inu_type == 3">
              <img src="@/assets/img/scenicSpot.svg" alt="景区" v-if="inu_type == 4">
            </div>
            <div class="text-overflow">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, onMounted, } from "vue";
  import { ElMessage } from "element-plus";
  import { VipApi, } from "@/plugins/api.js";
  export default {
    emits: ["submit",],
    setup(props, { emit }) {
      const dialogRef = ref(null);  // 对话框
      const currentRow = ref('');  // 当前会员数据
      const infoDatas = ref([]);  // 详情数据
      const typeLabels = ref([]);
      const typeActive = ref(0);  // 类型下标
      const inu_type = ref('');  // 1: 酒店；2：餐饮；3：门店；4：景区；5：阳光置业公司
      const auth_data = ref([]);  // 选择配置
      /**
       * 
       * 打开对话框
       * 
       * **/
      const openDialog = (row) => {
        currentRow.value = row;
        typeActive.value = 0;
        inu_type.value = 1;
        getUsingConfig(1);
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭对话框
       * 
       * **/
      const closeDialog = () => {
        dialogRef.value.close();
      }
      /**
       * 
       * 关闭加载状态
       * 
       * **/
      const closeDialogLoading = () => {
        dialogRef.value.isLoading = false;
      }
      /**
       * 
       * 获取详情数据
       * type:1：获取对应权限列表；2：提交保存
       * 
       * **/
      const getUsingConfig = (type) => {
        let params = {
          ir_id: currentRow.value.ir_id,
          action: type,
          inu_type: inu_type.value,
          auth_data: auth_data.value.join(","),
        };
        VipApi.integrationUsed(params).then((res) => {
          if (res.Code === 200) {
            // console.log("详情", params, res);
            if (type == 1) {
              // 获取对应权限列表
              typeLabels.value = res.Data.tab;
              infoDatas.value = res.Data.list;
            } else {
              // 提交保存
              ElMessage.success('操作成功！');
              closeDialogLoading();
              closeDialog();
              emit("submit");
            }
          } else {
            closeDialogLoading();
            ElMessage.error(res.Message);
          }
        });
      }
      /**
       * 
       * 切换类型标签
       * 
       * **/
      const onClickLabel = (i) => {
        typeActive.value = i;
        inu_type.value = typeLabels.value[i].value;
        getUsingConfig(1);
      }
      /**
       * 
       * 点击配置切换状态
       * 
       * **/
      const onClickInfoLabel = (row) => {
        row.is_checked = !row.is_checked;
      }
      /**
       * 
       * 确定提交按钮
       * 
       * **/
      const onConfirmBtn = () => {
        // console.log("提交", infoDatas.value);
        if (infoDatas.value.length == 0) {
          return false;
        }
        auth_data.value = [];
        infoDatas.value.forEach(item => {
          if (item.is_checked) {
            auth_data.value.push(item.value);
          };
        })
        dialogRef.value.isLoading = true;
        getUsingConfig(2);
      }
      onMounted(() => { });

      return {
        openDialog,
        VipApi,
        dialogRef,
        closeDialog,
        closeDialogLoading,
        getUsingConfig,
        infoDatas,
        typeLabels,
        typeActive,
        onClickLabel,
        onClickInfoLabel,
        inu_type,
        auth_data,
        onConfirmBtn,
      };
    },
  };
</script>

<style lang="scss">
  .usage-details-dialog {

    .details-type-title {
      background-color: #F3F4F8;
      border-radius: 4px;
      width: 126px;
      text-align: center;
      color: #5D6066;
      margin-right: 10px;
      height: 32px;
      line-height: 32px;
    }

    .details-type-active {
      background-color: var(--search-bg-color);
      color: var(--theme-color);
      border: 1px solid var(--theme-color);
    }

    .no-data {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }

    .info {
      max-height: 400px;
      overflow: auto;
      min-height: 140px;
    }

    .info-icon {
      margin: 3px 5px 0 0;
    }

    .info-content {
      background-color: #F3F4F8;
      border-radius: 4px;
      width: 268px;
      color: #5D6066;
      margin-right: 20px;
      height: 60px;
      line-height: 60px;
      padding: 0 10px;
    }

    .info-active {
      background-color: var(--search-bg-color);
      color: var(--theme-color);
      border: 1px solid var(--theme-color);
    }
  }
</style>